import React from "react"
import styles from "./styles.module.css";

import ContentPage from "../contentPage"
import { Link } from "gatsby";

const Page = () => (
    <ContentPage
      title={'Refer us to your friends & family\n& earn renewal discounts!'}
    >
      <div className={styles.container}>
        <p>In our journey thus far, we have grown thanks to word-of-mouth referrals from our customers & well-wishers! We hope to retain this approach as we move forward.</p>

        <p>So we humbly ask: do you love MProfit and wish to recommend us to your family & friends, so that they can also track their investments seamlessly?</p>

        <p>In return, we'd love to express our gratitude by sharing amazing discounts on your future MProfit renewals!</p>

        <p>If you are interested in helping us, then our Referral Program might be perfect for you.</p>
      </div>

      <div className={styles.container}>
        <p><strong>Here’s how it works:</strong></p>

        <ul>
          <li><strong>Get your unique Referral Link:</strong> As a paying MProfit customer, you will find your Referral Link by logging into your <ReferralAccountLink text={'MProfit Referral Panel'}/></li>

          <li><strong>Share the link with friends & family:</strong> Anyone who signs up for MProfit with your Referral Link will get a 10% discount on a new MProfit subscription.</li>

          <li><strong>Earn 1,500 points per referral:</strong> When someone you refer purchases a paid MProfit subscription within 90 days of signing up, you earn 1,500 Referral Points.</li>

          <li><strong>Convert points to discounts:</strong> You can convert Referral Points into direct discounts on your MProfit renewals. 1 Referral Point = 1 Rupee, meaning that you earn a 1,500 Rupee discount towards MProfit every time you refer a new customer!</li>
        </ul>

        <p>It's that simple. By participating in our referral program, you can earn meaningful savings on your MProfit subscription over time.</p>
      </div>

      <div>

      </div>

      <div className={styles.container}>
        <p><strong>Let’s consider an example:</strong></p>
        <p>Say your annual subscription is Rs. 6,000. If you successfully refer two new customers, you will earn a total of 3,000 Referral Points (1,500 x 2 referrals).</p>
        <p>These 3,000 points can be used to directly discount your next MProfit payment.</p>
        <p>In this example, a Rs. 3,000 discount on your next renewal — an incredible 50% off!</p>
      </div>

      <div className={styles.container}>
        <p><strong>Please note:</strong></p>
        <ul>
          <li>There's no cap on Referral Points, meaning you can earn more than needed to get your next renewal for free!</li>
          <li>Your Referral Points don't expire, allowing you to also carry them forward for future payments.</li>
          <li>To be eligible to earn Referral Points, simply ensure that a person you refer signs up with your Referral Link and makes a purchase within 90 days of signing up.</li>
          <li>Referral Points cannot be redeemed for cash or transferred to another account.</li>
        </ul>
      </div>

      <div>
        <p>So that’s our Referral Policy in a nutshell! You can view the complete list of terms & conditions <ReferralTermsLink text={'here'}/>.</p>
        <p><strong>Thank you for always supporting us!</strong></p>
      </div>

    </ContentPage>
)

const ReferralTermsLink = ({text}) => <Link to="/referral-terms">{text}</Link>

const ReferralAccountLink = ({text}) => <a href="/account/referral" target="_blank">{text}</a>

export default Page