import React from "react"

import Layout from "../components/flayout"
import ReferralPolicy from "../components/referralPolicy"

const Page = () => (
    <Layout darkHeader={true} seoFields={seoFields}>
        <ReferralPolicy />
    </Layout>
)

const seoFields = {
    description: "Earn renewal discounts for helping us grow!.",
    // lang,
    // meta,
    // keywords,
    title: "Referral Program" 
}

export default Page